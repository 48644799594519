export default {
  downloadFile(fileBlob, fileName) {
    let blob, objectURL

    blob = new Blob(fileBlob)
    objectURL = window.URL.createObjectURL(blob)

    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
      window.navigator.msSaveOrOpenBlob(blob, fileName)
    } else {
      let link = document.createElement('a')
      link.href = objectURL
      link.download = fileName
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
  },
  dataUrlType(url) {
    let fileType = {
      image: ['png', 'jpg', 'jpeg'],
      video: ['mp4']
    }

    let fileExtension

    if (url.includes('data:')) {
      fileExtension = url.split(';')[0].split(':')[1].split('/')[1]
    } else {
      fileExtension = url.split('.').pop()
    }

    for (let type in fileType) {
      if (fileType[type].indexOf(fileExtension) >= 0) {
        return type
      }
    }
    return null
  },
  downloadPdf(dom, title, size) {
    const JsPDF = require('jspdf').default
    const html2Canvas = require('html2canvas')
    html2Canvas(dom, {
      // html2Canvas(document.querySelector('#FilePdfDom'), { //这是在界面上设置一个id
      //只下载id为pdfDom的内容
      // html2Canvas(document.body, { //body是下载整个界面
      useCORS: true, //是否尝试使用CORS从服务器加载图像
      allowTaint: true,
      dpi: 300, //解决生产图片模糊
      scale: 3 //清晰度--放大倍数
      // width: 490, //canvas宽度
      // height: 240, //canvas高度
      // x: 0, //x坐标
      // y: 0, //y坐标
      // async: false, //是否异步解析和呈现元素
      // foreignObjectRendering: true, //是否在浏览器支持的情况下使用ForeignObject渲染
    }).then(function (canvas) {
      let PDF = new JsPDF('', 'pt', 'a4')
      // 圖片來自 html 寬度
      const sourceWidth = canvas.width
      // 圖片來自 html 高度
      const sourceHeight = canvas.height
      // 實際要放入 PDF 的寬度
      const actualWidth = (size * 595) / 21
      // 加入圖片至 PDF
      PDF.addImage(canvas.toDataURL('image/jpeg', 1.0), 'JPEG', 0, 0, actualWidth, sourceHeight * (actualWidth / sourceWidth))
      PDF.save(title + '.pdf')
    })
  }
}
